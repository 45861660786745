import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import VirtualCardsPage from "../../components/body/pages/virtual-cards"

const VirtualCards = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/virtual-cards"}
      title="Buy Airtime & Internet Data in Nigeria | Fast Recharge | Kuda"
      description="Buy Airtel, Glo, MTN and 9Mobile airtime and internet data directly from your Kuda App. We save your numbers making data & airtime recharges easy."
    />
    <VirtualCardsPage />
  </Layout>
)

export default VirtualCards
