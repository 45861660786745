import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../general/belowFoldCards"
import CTA from "../general/cta"
import VirtualCardsHeader from "./virtualCardsHeader"
import CardIcon from "../../../../assets/img/card-icon.inline.svg"
import LaptopIcon from "../../../../assets/img/laptop-fill.inline.svg"
import LockIcon from "../../../../assets/img/lock-fill.inline.svg"
import ImageTextLeft from "../general/imageTextLeft"
import ImageTextRight from "../general/imageTextRight"
import EntryIllustration from "../../../../assets/img/virtual-cards-illustration.png"
import StreamingIllustration from "../../../../assets/img/kuda-streaming-illustration.inline.svg"
import CardRequestIllustration from "../../../../assets/img/visa-processing-illustration.inline.svg"
import Card1 from "../../../../assets/img/blur/kuda-new-card-blur.inline.svg"
import { scrollToElement } from "../../../utility/utils"
import AnimationTextRight from "../general/animationTextRight"
const entryContent = {
  title: "Get a virtual Kuda Visa card for online payments.",
  subtitle: "The virtual Kuda Visa card works on Spotify, Netflix, Prime Video, Showmax and everywhere else naira Visa cards are accepted online!",
  illustration: EntryIllustration,
}

const kudaTopFeatures = [
  {
    icon: <CardIcon />,
    text: "Buy airtime from any Nigerian mobile network.",
  },
  {
    icon: <LaptopIcon />,
    text: "Top up airtime on your phone in a few seconds.",
  },
  {
    icon: <LockIcon />,
    text: "Secure your virtual card details with your PIN, fingerprint or Face ID.",
  },
]

const cardRequest = {
  payTitle: "Request a virtual card on the Kuda app.",
  subtitle:
    "You’ll get your card details on the app within 24 hours.",
  url: "/",
  name: "Learn How To Request A Virtual Card",
  payIllustration: <CardRequestIllustration />,
}

const easyPay = {
  payTitle: "Pay easily on the most popular streaming apps.",
  subtitle:
    " The virtual Kuda Visa card is accepted on Spotify, Netflix, Prime Video and Showmax.",
  url: "/",
  name: "Learn More About Virtual Card",
  payIllustration: <StreamingIllustration/>,
}

const easyGet = {
  title: "Easy to get, hard to crack.",
  subtitle:
    "Your virtual card details are always secured with your transaction PIN, fingerprint or Face ID.",
  url: "/",
  name: "Learn About Security On The Kuda App", 
  animation: "virtual-cards", 
}

const VirtualCardsPage = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <VirtualCardsHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        fallback={<Card1 className="blur isImage" />}
        isImage={true}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={cardRequest.payTitle}
        subtitle={cardRequest.subtitle}
        illustration={cardRequest.payIllustration}
        name={cardRequest.name}
        url={cardRequest.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={easyPay.payTitle}
        subtitle={easyPay.subtitle}
        illustration={easyPay.payIllustration}
        name={easyPay.name}
        url={easyPay.url}
        isExternal={true}
      />
      <AnimationTextRight
        title={easyGet.title}
        subtitle={easyGet.subtitle} 
        animationUrl={easyGet.animation}
        buttonName={easyGet.name}
        buttonUrl={easyGet.url}
        isExternal={true}
        fallback={<Card1 className="blur isImage" />}
      />     
      <CTA />
    </Fragment>
  )
}

export default VirtualCardsPage
